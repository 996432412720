@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: 'Oxanium';
	src: url('../public/fonts/Oxanium-SemiBold.woff2') format('woff2');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Sofia Sans';
	src: url('../public/fonts/SofiaSans-Regular.woff2') format('woff2');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'Sofia Sans';
	src: url('../public/fonts/SofiaSans-Medium.woff2') format('woff2');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
}

body {
	width: 100%;
	color: white;
}
